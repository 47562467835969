import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import * as echarts from "echarts";

import { postRequest, getRequest } from "./utils/request.js";
import { average, pushData, getBarTime } from "./utils/average.js";

import "./assets/js/chalk";
import "./assets/css/global.css";
import scroll from "vue-seamless-scroll";
import axios from "axios";
import VueAnimateNumber from "vue-animate-number";
import "swiper/swiper-bundle.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css"; //element-ui的css
Vue.use(ElementUI); //使用elementUI

axios.interceptors.request.use((config) => {
  //为请求头对象添加token验证的 Authorization 字段
  if (config.url == "https://jxautts.top:444/api/v1/export")
    config.headers.Authorization = window.localStorage.getItem("token");
  console.log(config);
  return config;
});

Vue.prototype.$echarts = echarts;
Vue.prototype.$post = postRequest;
Vue.prototype.$get = getRequest;
Vue.prototype.$average = average;
Vue.prototype.$pushData = pushData;
Vue.prototype.$getBarTime = getBarTime;
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
Vue.use(scroll);
Vue.use(VueAwesomeSwiper);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
